import { sideBannerActions, SideBannerType } from 'modules/sideBanner/stores/sideBannerStore';

import { DebuggingToolComponent } from '../types';

const SideBannersTrigger = () => {
  return (
    <div className="mt-4 flex flex-col">
      <div className="text-lg font-bold">Side Banner Triggers</div>
      <button
        onClick={() =>
          sideBannerActions.add({
            type: SideBannerType.CSAT
          })
        }
        className="text-glass-500 bg-amber-300 border-b border-black mb-1 rounded-100 hover:border-gray-400"
      >
        Show CSAT Side Banner
      </button>
      <button
        onClick={() =>
          sideBannerActions.add({
            type: SideBannerType.UPSELL
          })
        }
        className="text-glass-500 bg-amber-300 border-b border-black mb-1 rounded-100 hover:border-gray-400"
      >
        Show Mobile Upsell Banner
      </button>
      <button
        onClick={() =>
          sideBannerActions.add({
            type: SideBannerType.UNLIMITED_SPEED
          })
        }
        className="text-glass-500 bg-amber-300 border-b border-black mb-1 rounded-100 hover:border-gray-400"
      >
        Show Unlock Unlimited Speed Banner
      </button>
    </div>
  );
};

export default {
  component: SideBannersTrigger,
  condition: async () => true
} satisfies DebuggingToolComponent;
