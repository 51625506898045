import React, { Fragment } from 'react';

import Switch from 'components/newListeningExperience/shared/Switch';
import { SubscriptionStoreState, useSubscriptionStore } from 'modules/subscription/stores/subscriptionStore';

import { DebuggingToolComponent } from '../types';

const EntitlementsInspector = () => {
  const entitlements = useSubscriptionStore(state => state.entitlements);
  const { hdWordsLeft, ...metadata } = entitlements ?? {
    hdWordsLeft: 0
  };
  const isPremium = useSubscriptionStore(state => state.isPremium);

  const handlePremiumChange = (checked: boolean) => {
    useSubscriptionStore.setState(
      state =>
        ({
          isPremium: checked,
          entitlements: {
            ...state.entitlements,
            isPremium: checked
          }
        }) as SubscriptionStoreState
    );
  };

  const handleHdWordsLeftChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    useSubscriptionStore.setState(
      state =>
        ({
          entitlements: {
            ...state.entitlements,
            hdWordsLeft: isNaN(value) ? 0 : value
          }
        }) as SubscriptionStoreState
    );
  };

  return (
    <div className="mt-4 flex flex-col w-full">
      <div className="text-lg font-bold mb-4 truncate">Entitlements</div>
      <div className="w-full grid grid-cols-[minmax(0,1fr),auto] gap-x-2 gap-y-2 items-center [&>*:nth-child(even)]:justify-self-end [&>*:nth-child(odd)]:min-w-10">
        <div className="text-glass-500">Premium</div>
        <Switch checked={!!isPremium} onChange={handlePremiumChange} />
        <div className="text-glass-500">HD Words Left</div>
        <input type="number" value={hdWordsLeft} onChange={handleHdWordsLeftChange} className="border rounded px-2 py-1 max-w-25" />
        {Object.entries(metadata).map(([key, value], index) => (
          <Fragment key={`item-${index}`}>
            <div className="text-glass-500">{key}</div>
            <div className="line-clamp-2 text-end max-w-[50%] overflow-hidden overflow-ellipsis border-b">{`${value}`}</div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default {
  component: EntitlementsInspector,
  condition: async () => true
} satisfies DebuggingToolComponent;
