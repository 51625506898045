import React from 'react';
export default function Microphone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00011 0.230713C5.94557 0.230713 4.28004 1.89624 4.28004 3.95078V10.1509C4.28004 12.2054 5.94557 13.871 8.00011 13.871C10.0546 13.871 11.7202 12.2054 11.7202 10.1509V3.95079C11.7202 1.89625 10.0546 0.230713 8.00011 0.230713ZM2.21342 7.25747C2.21342 6.80091 1.84331 6.43079 1.38674 6.43079C0.930177 6.43079 0.560059 6.80091 0.560059 7.25747V10.1509C0.560059 13.9805 3.45344 17.1343 7.17343 17.5456V20.1158H4.27998C3.82341 20.1158 3.45329 20.4859 3.45329 20.9425C3.45329 21.3991 3.82341 21.7692 4.27998 21.7692H11.7201C12.1767 21.7692 12.5468 21.3991 12.5468 20.9425C12.5468 20.4859 12.1767 20.1158 11.7201 20.1158H8.82679L8.82679 17.5456C12.5469 17.1344 15.4403 13.9805 15.4403 10.1509V7.25747C15.4403 6.80091 15.0702 6.43079 14.6137 6.43079C14.1571 6.43079 13.787 6.80091 13.787 7.25747V10.1509C13.787 13.3468 11.1962 15.9376 8.0002 15.9376C4.80425 15.9376 2.21342 13.3468 2.21342 10.1509V7.25747Z"
        fill="currentColor"
      />
    </svg>
  );
}
